<template>
  <v-row>
    <v-col cols="12">
      <div class="card-title m-0 capitalize">{{ currentRouteName }}</div>
    </v-col>
    <v-col cols="12" lg="12" md="12">
      <v-row>
        <v-col cols="12" md="3">
          <!-- test -->
          <crypto-currency-card
            bg-card-color="bg-primary"
            heading="Data"
            sub-heading="0/0"
            desc="Verifikasi vs Mentah"
            main-icon-text-color="text-blue-400"
            main-icon-name="mdi-file-compare"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-danger"
            heading="Periode Hitung Terakhir"
            sub-heading="12 April 2021"
            desc="Jawa Barat"
            main-icon-text-color="text-red-400"
            main-icon-name="mdi-calendar-check"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-success"
            heading="Learning Model Terakhir"
            sub-heading="1 Februari 2021"
            desc="Coansole Execution Update"
            main-icon-text-color="text-green-300"
            main-icon-name="mdi-calendar-sync"
          />
        </v-col>
        <v-col cols="12" md="3">
          <crypto-currency-card
            bg-card-color="bg-warning"
            heading="Total Bencana Gempa"
            sub-heading="0"
            desc="1 tahun terakhir"
            main-icon-text-color="text-red-300"
            main-icon-name="mdi-earth"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="4" md="12">
      <base-card>
        <v-card-title style="display: block;text-align: center;font-weight: 100;font-size: 1rem;"> Summary Verifikasi Data</v-card-title>
        <v-card-text>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12" lg="4" md="12">
      <base-card>
        <v-card-title style="display: block;text-align: center;font-weight: 100;font-size: 1rem;">Trend Rate Resiko Gempa Saat ini</v-card-title>
        <v-card-text>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12" lg="4" md="12">
      <base-card>
        <v-card-title style="display: block;text-align: center;font-weight: 100;font-size: 1rem;">Kejadian Gempa Terbaru</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Waktu</th>
                  <th class="text-left">Keterangan</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import CryptoCurrencyCard from "@/components/card/CryptoCurrencyCard";

export default {
  name: "dashboard",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Dashboard",
  },
  components: {
    CryptoCurrencyCard,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
    };
  },
};
</script>
